<template>
  <!-- 资料库>新增资料 -->
  <div class="dict">
    <el-page-header
      style="margin-bottom: 1.04167vw"
      @back="goBack"
      :content="'目标列表/更新进度/'+editTitle"
    >
      <template slot="title">
        <i class="el-icon-arrow-left"></i>返回
      </template>
    </el-page-header>
    <el-form
      v-if="$route.query.type == 4"
      ref="ruleForms"
      style="width: 80%"
      :model="form"
      :label-width="labelWidth"
      :rules="rules"
      :disabled="formDiasble"
    >
      <el-form-item
        label="园校名称："
        prop="name"
      >
        <el-input
          v-model="form.name"
          maxlength="100"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="详细进度：" prop="">
      </el-form-item> -->
      <el-form-item
        :label="item.name+'：'"
        :key="index"
        v-for="(item, index) in target_new_garden"
      >
        <el-checkbox-group
          v-model="checkboxList"
          v-if="item.list"
        >
          <el-checkbox
            v-for="(citem, cindex) in item.list"
            :label="citem.value"
            @change="checkTarget"
            :key="cindex"
          >{{ citem.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="this.formDiasble==false">
        <el-button
          @click="goBack"
          class="reset"
        >返回</el-button>
        <el-button
          type="primary"
          @click="saveRegistrationPark(1)"
          v-loading="saveLoading"
          :disabled="saveLoading"
        >保存</el-button>
      </el-form-item>
    </el-form>
    <el-form
      v-if="$route.query.type == 5"
      ref="ruleForm"
      style="width: 80%"
      :model="ruleForm"
      label-width="6.25vw"
      :rules="rules"
      :disabled="formDiasble"
    >
      <el-form-item
        label="园校名称："
        prop="name"
      >
        <el-input
          v-model="ruleForm.name"
          placeholder="请输入园校名称"
          maxlength="10"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item
        label="所在城市："
        prop="province"
      >
        <!-- <el-input
          v-model="ruleForm.province"
          placeholder="请输入所在城市"
          maxlength="100"
          show-word-limit
        ></el-input> -->
        <el-cascader
          class="city"
          v-model="ruleForm.province"
          :options="cityList"
          :props="cityProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item
        label="建筑面积："
        prop="buildingArea"
      >
        <el-input
          v-model="ruleForm.buildingArea"
          maxlength="10"
          placeholder="请输入建筑面积"
        >
          <template slot="append">m²</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="户外面积："
        prop="outdoorArea"
      >
        <el-input
          v-model="ruleForm.outdoorArea"
          maxlength="10"
          placeholder="请输入户外面积"
        >
          <template slot="append">m²</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="班额："
        prop="targetValue"
      >
        <!-- type="number" -->
        <el-input
          v-model="ruleForm.targetValue"
          placeholder="请输入班额"
          :maxlength="10"
          oninput="value=value.replace(/[^\d]/g,'')"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item
        label="开园时间："
        prop="startTime"
      >
        <el-date-picker
          style="width:100%;"
          v-model="ruleForm.startTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="签约情况："
        prop="isSign"
      >
        <el-select
          v-model="ruleForm.isSign"
          style="width:100%"
          placeholder="请选择"
        >
          <el-option
            v-for="item in signSituationOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="管理类型："
        prop="manageType"
      >
        <el-select
          v-model="ruleForm.manageType"
          style="width:100%"
          placeholder="请选择"
        >
          <el-option
            v-for="item in manageTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="goBack"
          class="reset"
        >返回</el-button>
        <el-button
          type="primary"
          @click="saveRegistrationPark(2)"
          v-loading="saveLoading"
          :disabled="saveLoading"
        >保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
import { parse } from 'qs';
export default {
  data () {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: "",
        gardenDetailList: [],
        detailId: '',
      },
      formDiasble: false,
      checkList: [],
      target_new_garden: [],
      editTitle: '新增',
      checkboxList: [],
      signSituationOptions: [
        {
          value: '0',
          label: '未签约'
        },
        {
          value: '1',
          label: '签约'
        }
      ],
      manageTypeOptions: [
        {
          value: '1',
          label: '直营'
        },
        {
          value: '2',
          label: '管理直营'
        }
      ],
      ruleForm: {
        buildingArea: '',
        name: '',
        city: '',
        county: '',
        detailId: '',
        isSign: '',
        province: [],
        id: '',
      },
      saveLoading: false,

      rules: {
        name: [{ required: true, message: "请输入园校名称", trigger: "blur" }],
        province: [{ required: true, message: "请选择所在城市", trigger: "blur" }],
        buildingArea: [
          { required: true, message: "请输入建筑面积", trigger: "blur" },
        ],
        outdoorArea: [
          { required: true, message: "请输入户外面积", trigger: "blur" },
        ],
        targetValue: [
          { required: true, message: "请输入班额", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "请选择开园时间", trigger: "blur" },
        ],
        isSign: [
          { required: true, message: "请选择签约情况", trigger: "blur" },
        ],
        manageType: [
          { required: true, message: "请选择管理类型", trigger: "blur" },
        ],
      },
      cityList: [],
      cityProps: {
        label: 'name',
        value: 'code',
        children: 'clildList'
      },

      labelWidth: '120px',
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler (val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler (val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            // console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created () {
    this.formDiasble = !!this.$route.query.detail
    if (this.$route.query.id) {
      if (this.formDiasble == false) {
        this.editTitle = '编辑'
      } else {
        this.editTitle = '查看'
      }
      this.ruleForm.id = this.$route.query.id
      this.getDetail()
    }
    if (this.$route.query.type == 5) {
      this.region()
    }
    this.getTarget_new_garden();
  },
  mounted () {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
    if (window.innerWidth < 1300) {
      this.labelWidth = '80px'
    } else {
      this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
    }
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    checkTarget (b, e) {
      let id = e.target.defaultValue;
      // console.log(b,id)
      if (this.checkList.indexOf(id) != -1) {
        this.checkList.splice(this.checkList.indexOf(id), 1);
      } else {
        this.checkList.push(id);
      }
    },
    goBack () {
      this.$router.go(-1);
    },
    getDetail () {
      if (this.$route.query.type == 4) {
        this.$api.newGardenDetail(this.$route.query.id).then((res) => {
          if (res.data.code == 0) {
            this.form = res.data.data;
            this.checkboxList = []
            this.checkboxList = this.form.gardenDetailList.map((item) => {
              return item.value
            })
            console.log(this.checkboxList, '1111111')
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$api.expandGardenDetail(this.$route.query.id).then((res) => {
          if (res.data.code == 0) {
            this.ruleForm = res.data.data;
            if (this.ruleForm.isSign.label) {
              this.ruleForm.isSign = this.ruleForm.isSign.value
            }
            if (this.ruleForm.manageType.label) {
              this.ruleForm.manageType = this.ruleForm.manageType.value
            }
            if (this.ruleForm.county) {
              this.ruleForm.province = [this.ruleForm.province, this.ruleForm.city, this.ruleForm.county]
            } else {
              this.ruleForm.province = [this.ruleForm.province, this.ruleForm.city]
            }
            // this.checkboxList = this.form.gardenDetailList.map((item)=>{
            //   return item.value
            // })
            // console.log(this.checkboxList)
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    saveRegistrationPark (saveType) {
      let gardenDetailList = []
      if (saveType == 1) {
        if (this.checkboxList) {
          console.log(this.checkboxList)
          this.checkboxList.map(val => {
            this.target_new_garden.forEach(e => {
              if (e.list) {
                // console.log(e.list)
                e.list.forEach(ele => {
                  if (ele.value == val) {
                    let obj = {
                      value: val,
                      doneValue: ele.description
                    }
                    gardenDetailList.push(obj)
                  }
                })
              }
            })
          })
        }
        console.log(gardenDetailList)
        // return
        this.form.detailId = this.$route.query.detailId
        this.form.gardenDetailList = gardenDetailList;
      } else if (saveType == 2) {
        // if (this.ruleForm.name.replace(/\n/g, "").length < 2) {
        //   this.$message.error("园校名称输入长度需在2-100之间");
        //   return;
        // }
        this.ruleForm.detailId = this.$route.query.detailId
      }
      if (!this.$route.query.id) {
        // 园所拓展
        if (this.$route.query.type == 4) {
          if (this.form.gardenDetailList.length == 0) {
            this.$message.error('请选择详细进度！')
            return
          }
          this.$refs.ruleForms.validate((valid) => {
            if (valid) {
              this.saveLoading = true
              this.$api.savePark((this.form)).then((res) => {
                this.saveLoading = false
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "添加成功",
                  });
                  this.goBack()
                } else {
                  this.$message.error(res.data.savePark);
                }
              });
            }
          })
        } else if (this.$route.query.type == 5) {
          // 新园开办
          this.$refs.ruleForm.validate((valid) => {
            if (valid) {
              let params = JSON.parse(JSON.stringify(this.ruleForm))
              console.log(params)
              params.city = this.ruleForm.province[1]
              params.county = this.ruleForm.province[2]
              params.province = this.ruleForm.province[0]
              this.saveLoading = true
              this.$api.expandGardenSave(params).then((res) => {
                this.saveLoading = false
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "添加成功",
                  });
                  this.goBack()
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            }
          })
        }
      } else {
        if (this.$route.query.type == 4) {
          this.$refs.ruleForms.validate((valid) => {
            if (valid) {
              this.saveLoading = true
              this.$api.newGardenUpdata(this.form).then((res) => {
                this.saveLoading = false
                // console.log(res);
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "添加成功",
                  });
                  this.goBack()
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            }
          })
        } else if (this.$route.query.type == 5) {
          this.$refs.ruleForm.validate((valid) => {
            if (valid) {
              let params = JSON.parse(JSON.stringify(this.ruleForm))
              params.city = this.ruleForm.province[1]
              params.county = this.ruleForm.province[2]
              params.province = this.ruleForm.province[0]
              this.saveLoading = true
              this.$api.expandGardenUpdata(params).then((res) => {
                this.saveLoading = false
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "添加成功",
                  });
                  this.goBack()
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            }
          })
        }
      }
    },
    getTarget_new_garden () {
      this.$api.getDictionaryTree({ type: "target_new_garden" }).then((res) => {
        let data = res.data ? res.data.data : null;
        if (data) {
          data.forEach((item) => {
            let temp = {
              id: item.id,
              name: item.name,
              list: [],
              type: null,
              check: [],
            };
            if (item.dictList && item.dictList.length) {
              temp.list = item.dictList;
              temp.type = item.dictList[0].type;
            }
            this.target_new_garden.push(temp);
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    region (callback) {
      this.$api.region().then(res => {
        if (res.data.code == 0) {
          console.log(res.data.data)
          this.cityList = res.data.data
          callback && callback()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100% - 62px);
  }
}
.city {
  width: 100%;
}
</style>
